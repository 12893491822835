// This script dynamically loads the common header and footer used across LHC applications.
// Include a script tag referencing "https://lhcforms.nlm.nih.gov/shared/loadSharedNavLinks.min.js" in your page.

const sharedNavLinksBaseUrl = 'https://lhcforms.nlm.nih.gov/';

const navLinksRequest = new XMLHttpRequest();
navLinksRequest.onload = function () {
  document.getElementById('sharedNavLinks')
    .innerHTML = navLinksRequest.responseText;
};
navLinksRequest.open('GET', sharedNavLinksBaseUrl + 'shared/nav-links.html');
navLinksRequest.send();
